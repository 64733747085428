"use client";
import {
  SearchBar as SearchComponent,
  getBookSource,
  getAuthorSource,
  SearchContext as Context,
} from "@cappelendamm/product-search";
import {
  searchClient,
  bookIndex,
  authorIndex,
  querySuggestionsIndex,
} from "./client";
import { FC, ReactNode } from "react";

export const SearchBar = () => {
  return (
    <SearchComponent
      searchClient={searchClient}
      querySuggestionsIndex={querySuggestionsIndex}
      querySources={({ query }) => [
        getBookSource({ query, indexName: bookIndex, searchClient }),
        getAuthorSource({ query, indexName: authorIndex, searchClient }),
      ]}
    />
  );
};

export const SearchContext: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Context searchClient={searchClient} bookIndex={bookIndex}>
      {children}
    </Context>
  );
};
